/**
 * privacy.jsx
 * プライバシーポリシーページ
 */

// (1) import層
import React from "react"
// import { Link } from "gatsby"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import { Layout, FltShopBtn } from "../components/index"//サイト全体のコンポーネント
import { LowerHeader, LowerHeaderBg } from "../components/LowerPages/index"
// import SlideInSection from "../components/SlideInSection/SlideInSection"
// import styled from "styled-components"
import "../styles/LowerPage.scss";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import "../styles/Privacy.scss";

// (2) Types層

// (3) DOM層
const Privacy = () => {
  const title1 = [
    { "id": 0, "text": "p" },
    { "id": 1, "text": "r" },
    { "id": 2, "text": "i" },
    { "id": 3, "text": "v" },
    { "id": 4, "text": "a" },
    { "id": 5, "text": "c" },
    { "id": 6, "text": "y" },
    { "id": 7, "text": '' },
    { "id": 8, "text": "p" },
    { "id": 9, "text": "o" },
    { "id": 10, "text": "l" },
    { "id": 11, "text": "i" },
    { "id": 12, "text": "c" },
    { "id": 13, "text": "y" },
  ];
  const breakpoints = useBreakpoint();
  return (
    <>
      <Layout className={`privacy`} pageName="privacy" title="プライバシーポリシー">
        <Seo
          lang="ja"
          title={`プライバシーポリシー`}
          description="PRIVACY POLICY 個人情報保護方針"
        />
        <div className={`LowerHeadImg`}>
          {
            breakpoints.sm ?
              <StaticImage
                layout="fullWidth"
                src="../images/privacy/privacy_header-sp.png"
                alt="business_header"
              />
              :
              <StaticImage
                layout="fullWidth"
                src="../images/privacy/privacy_header.png"
                alt="business_header"
              />

          }
        </div>
        <div className={`LowerWrapper`}>
          <LowerHeader data={title1} title={`個人情報保護方針`}>
            <LowerHeaderBg />
          </LowerHeader>
          <div className={`LowerContent SlideInWrp`}>
            <div className={`RadiusCircle`} ></div>
            <div className={`LowerLead`}>
              <p>

                深川養鶏農業協同組合は、<br className={`smBr`} />個人情報を扱う企業として、<br />
                個人情報の保護に努めることを<br className={`smBr`} />社会的責務として認識し、<br />
                「個人情報保護方針」を定め、<br className={`smBr`} />これを実践することを宣言いたします。
              </p>
            </div>
            <section className={`LowerSection`}>
              <ol className={`privacyList`}>
                <li>
                  <h3>1．個人情報の収集・利用について</h3>
                  <p>
                    個人情報の収集（取得）は、利用目的を明確に定め、適性かつ公正な手段によってこれを実施いたします。また、個人情報の利用は、予め定めた利用目的の達成に必要な限度においてこれを行い、個人の同意を得た範囲においてのみ提供いたします。
                  </p>
                </li>
                <li>
                  <h3>2．個人情報の安全管理について</h3>
                  <p>個人情報は厳重な管理体制のもとに保管し、個人情報への不正アクセス、個人情報の紛失、破壊、改ざん、漏洩等を予防するため、情報セキュリティ体制を徹底します。</p>
                </li>
                <li>
                  <h3>3．個人情報保護に関する法令その他の規範の遵守について</h3>
                  <p>個人情報を取扱う業務の遂行にあたっては、個人情報保護に関する国内法令、その他の規範を遵守して行動いたします。</p>
                </li>
                <li>
                  <h3>4．個人情報保護に関する管理体制と仕組みについて</h3>
                  <p>
                    個人情報保護に関する管理体制と仕組みについて継続的な見直しおよび改善をはかってまいります。
                    当社は、本ポリシーを従業員はもとより業務に係る者一人ひとりがその主旨と意図を十分に理解した上で、 細心の注意を払って日常の業務遂行にあたり、個人情報の保護に努めてまいります。
                  </p>
                </li>
              </ol>
            </section>
          </div>
        </div>
        <FltShopBtn />
      </Layout>
    </>
  )
}

export default Privacy

// (4) Style層

// (5) Container層
